import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO, {
  createQueryImageFromFile,
  concatURL,
  StructuredData,
  SEOImage,
} from "../components/SEO";
import ExpertNetwork from "components/ExpertNetwork";
import { LinkButton } from "components/Buttons";
import getIcon from "components/Icons";

import styles from "./styles.module.less";

export const pageQuery = graphql`
  # Fragment with what's necessary for the view
  fragment ExpertNetwork on MarkdownRemark {
    html
    frontmatter {
      title
      category
      headquarters
      inactive
      tags
      website
      logo {
        image {
          ...ENThumbnailImage
          ...OpenGraphImage
          ...TwitterImage
          ...StructuredDataImage
        }
        alt
        caption
      }
    }
  }
  query ExpertNetworkPage($id: String!) {
    markdownRemark(frontmatter: { published: { eq: true } }, id: { eq: $id }) {
      ...ExpertNetwork
      # Additional fields for SEO
      frontmatter {
        description
        date
      }
      excerpt(pruneLength: 160)
      fields {
        dateModified
      }
    }
  }
`;

/**
 * @typedef {object} Props
 * @prop {Location} location
 * @prop {GatsbyTypes.ExpertNetworkPageQuery} data
 *
 * @param {Props} props
 */
export default function ExpertNetworkPage(props) {
  const {
    location: { pathname },
    data,
  } = props;

  const logo = data.markdownRemark.frontmatter.logo;
  const [openGraph, twitter, sdPrimaryImage] = logo.image
    ? createQueryImageFromFile(logo, [
        "openGraphImage.resize",
        "twitterImage.resize",
        "sdImage.resize",
      ])
    : [null, null, null];

  const {
    markdownRemark: {
      excerpt,
      frontmatter: { title, description, date, tags, website, headquarters },
      fields: { dateModified },
    },
  } = data;

  return (
    <Layout gradientBackground>
      <SEO
        isArticle
        articleType="AnalysisNewsArticle"
        title={title}
        description={description || excerpt}
        pathname={pathname}
        dateModified={new Date(dateModified)}
        datePublished={new Date(date)}
        postTags={tags}
        images={{ openGraph, twitter, sdPrimaryImage }}
        createOtherStructuredData={({ article, siteUrl, inLanguage }) => {
          const sdLogoImage = SEOImage.createSEOImage(sdPrimaryImage, {
            baseUrl: siteUrl,
          });

          const logo = StructuredData.createImageObject({
            id: concatURL(siteUrl, pathname, "#logo"),
            inLanguage,
            image: sdLogoImage,
          });

          const enOrganization = StructuredData.createOrganizationObject({
            id: concatURL(siteUrl, pathname, "#organization"),
            name: title,
            url: concatURL(siteUrl, pathname),
            social: website && [{ url: website }],
            logo,
            description,
            location: headquarters,
            subjectOf: article,
          });

          return [enOrganization];
        }}
      />
      <div className={styles.regularContainer}>
        <LinkButton
          title="Directory"
          icon={getIcon("arrowForward", { rotate: 180 })}
          to={"/expert-network-directory"}
          reversed={true}
        />
        <ExpertNetwork {...data.markdownRemark} />
      </div>
    </Layout>
  );
}
