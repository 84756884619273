import React from "react";
import { Row, Col } from "antd";

import Image from "components/Image";
import RichText from "components/RichText";
// TODO: better folder structure
import ENFields from "components/ENThumbnail/ENFields";

import styles from "./ExpertNetwork.module.less";

/** @param {GatsbyTypes.ExpertNetworkFragment} props */
export default function ExpertNetwork(props) {
  const {
    html,
    frontmatter: {
      logo,
      title,
      website,
      headquarters,
      tags,
      category,
      inactive,
    },
  } = props;
  return (
    <div className={styles.container}>
      <h1 className={styles.name}>{title}</h1>
      <Row
        gutter={{
          lg: 48,
        }}
        align="top"
      >
        <Col xs={24} lg={6} className={styles.column}>
          {logo.image && (
            <div className={styles.logoContainer}>
              <Image
                publicURL={logo.image.publicURL}
                src={logo.image.childImageSharp}
                alt={logo.alt}
              />
            </div>
          )}
        </Col>
        <Col xs={24} lg={18} className={styles.column}>
          <RichText html={html} />
          <ENFields
            className={styles.fields}
            category={category}
            headquarters={headquarters}
            inactive={inactive}
            tags={tags}
            website={website}
          />
        </Col>
      </Row>
    </div>
  );
}
